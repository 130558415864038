import React from 'react'
import PropTypes from 'prop-types';
import PaginaProyectos from '../components/pageProyectos'

const PaginaProyectosEs = ({ pageContext }) => {
  return <PaginaProyectos lang='es' category={pageContext?.category} />
}
PaginaProyectosEs.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
export default PaginaProyectosEs