import React from 'react';
import PropTypes from 'prop-types';
import Proyectos from './proyectos';
import Layout from './layout';
import Head from './head';
import { tags, useTranslation } from '../helpers/i18n';

const PaginaProyectos = ({ category, lang }) => {
  const l = (...texts) => lang === 'en' ? texts[1] : texts[0]
  const t = (key) => useTranslation(key, lang)
  const title = category
    ? l(`Proyectos de ${category}`, `${t(category)} projects`)
    : l('Proyectos de Producción Virtual, Mapping 3D, Realidad Virtual, animación, aplicaciones y museos',
      'Virtual production, virtual reality, augmented reality, animation, video projection mapping and museum projects.')
  const tagName = category
    ? l(`de ${category}`, `${t(category)}`)
    : '';
    
  const pageDescription = l(`Ejemplos de proyectos de ${tagName} realizados por la agencia Visualma, México. Expertos en contenido digital, 3D, animación y programación.`, 
    `Examples of ${tagName} projects created by Visualma. Experts in digital content, 3D animation, virtual production and programming.`)
  const categoryDescription = tags[lang][category] || l(`Algunos de los proyectos de ${category} realizados por Visualma en nuestros diez años de experiencia como creadores de contenido digital en México.`, `Some of the ${category} projects created by Visualma in our 10 years as digital content creators.`)

  return (
    <Layout lang={lang}>
      <Head lang={lang} pageTitle={title} pageDescription={pageDescription} />
      <Proyectos
        lang={lang}
        title=""
        category={category || ''}
        categoryDescription={categoryDescription}
        serviceUrl={category }
      />
    </Layout>
  );
};
PaginaProyectos.propTypes = {
  category: PropTypes.string,
  lang: PropTypes.string,
};

export default PaginaProyectos;
